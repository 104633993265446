var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin-wrapper',[_c('app-loader',{attrs:{"value":_vm.$apollo.loading}}),_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-container',[(_vm.accountData && !_vm.accountData.canCreateSubusers)?_c('v-alert',{attrs:{"type":"warning","outlined":""}},[_vm._v(" You must upgrade your account in order to create more users ")]):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"sort-by":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('nice-header',{scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('v-spacer'),_c('new-user-form',{on:{"create":_vm.refresh}})]},proxy:true}])},[_vm._v(" Users ")])]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.firstName)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.username)+" ")]}},{key:"item.stores",fn:function(ref){
var item = ref.item;
return [(item.admin)?_c('span',[_vm._v("ALL")]):_vm._e(),_c('ul',{staticClass:"pl-0"},_vm._l((item.accessibleStores),function(store,i){return _c('li',{key:i,staticStyle:{"list-style-type":"none"}},[_vm._v(" "+_vm._s(store.name)+" ")])}),0)]}},{key:"item.admin",fn:function(ref){
var item = ref.item;
return [(item.admin)?_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-close-circle")])]}},{key:"item.canCreateStores",fn:function(ref){
var item = ref.item;
return [(item.canCreateStores)?_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-close-circle")])]}},{key:"item.canEditStore",fn:function(ref){
var item = ref.item;
return [(item.canEditStore)?_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-close-circle")])]}},{key:"item.canAddUsers",fn:function(ref){
var item = ref.item;
return [(item.canAddUsers)?_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-close-circle")])]}},{key:"item.canEditBilling",fn:function(ref){
var item = ref.item;
return [(item.canEditBilling)?_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-close-circle")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.passwordSet)?_c('span',[_vm._v("Active")]):_c('span',[_vm._v(" Not yet activated ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('edit-user',{attrs:{"profile":item,"users":_vm.users},on:{"save":_vm.refresh}}),_c('delete-user',{attrs:{"profile":item},on:{"delete":_vm.refresh}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }