<template>
    <admin-wrapper>
        <app-loader :value="$apollo.loading"/>
        <v-card flat tile>
            <v-container>
                <v-alert type="warning" outlined v-if="accountData && !accountData.canCreateSubusers">
                    You must upgrade your account in order to create more users
                </v-alert>
                <v-data-table
                        :headers="headers"
                        :items="users"
                        sort-by="name"
                >
                    <template v-slot:top>
                        <nice-header>
                            Users
                            <template v-slot:extra>
                                <v-spacer></v-spacer>
                                <new-user-form @create="refresh"/>
                            </template>
                        </nice-header>
                    </template>
                    <template v-slot:item.name="{ item }">
                        {{item.user.firstName}}
                    </template>
                    <template v-slot:item.email="{ item }">
                        {{item.user.username}}
                    </template>
                    <template v-slot:item.stores="{ item }">
                      <span v-if="item.admin">ALL</span>
                      <ul class="pl-0">
                        <li v-for="(store, i) in item.accessibleStores" :key="i" style="list-style-type: none">
                          {{ store.name }}
                        </li>
                      </ul>
                    </template>
                    <template v-slot:item.admin="{ item }">
                        <v-icon color="success" v-if="item.admin" small>mdi-check-circle</v-icon>
                        <v-icon v-else color="error" small>mdi-close-circle</v-icon>
                    </template>
                    <template v-slot:item.canCreateStores="{ item }">
                        <v-icon color="success" v-if="item.canCreateStores" small>mdi-check-circle</v-icon>
                        <v-icon v-else color="error" small>mdi-close-circle</v-icon>
                    </template>
                    <template v-slot:item.canEditStore="{ item }">
                        <v-icon color="success" v-if="item.canEditStore" small>mdi-check-circle</v-icon>
                        <v-icon v-else color="error" small>mdi-close-circle</v-icon>
                    </template>
                    <template v-slot:item.canAddUsers="{ item }">
                        <v-icon color="success" v-if="item.canAddUsers" small>mdi-check-circle</v-icon>
                        <v-icon v-else color="error" small>mdi-close-circle</v-icon>
                    </template>
                    <template v-slot:item.canEditBilling="{ item }">
                        <v-icon color="success" v-if="item.canEditBilling" small>mdi-check-circle</v-icon>
                        <v-icon v-else color="error" small>mdi-close-circle</v-icon>
                    </template>
                    <template v-slot:item.status="{ item }">
                        <span v-if="item.passwordSet">Active</span>
                        <span v-else>
                            Not yet activated
                        </span>

                    </template>
                    <template v-slot:item.actions="{ item }">
                        <edit-user :profile="item" :users="users" @save="refresh"></edit-user>
                        <delete-user :profile="item" @delete="refresh"></delete-user>
                    </template>
                </v-data-table>
            </v-container>
    </v-card>
    </admin-wrapper>
</template>

<script>
    import { users } from '@/graphql'
    import { accountData } from '@/graphql'

    export default {
        apollo: { users, accountData },
        data: () => ({
            headers: [
                {text: 'Name', value: 'name' },
                {text: 'Email address', value: 'email' },
                {text: 'Admin?', value: 'admin' },
                {text: 'Can create users?', value: 'canAddUsers' },
                {text: 'Can edit store?', value: 'canEditStore' },
                {text: 'Can edit billing?', value: 'canEditBilling' },
                {text: 'Can create stores?', value: 'canCreateStores' },
                {text: 'Stores', value: 'stores' },
                {text: 'Status', value: 'status' },
                {text: 'Actions', value: 'actions' }
            ]
        }),
        methods: {
            refresh () {
                this.$apollo.queries.users.refetch()
            }
        }
    }
</script>
